import React, { useEffect, useState } from 'react';
import sanityClient from '../client.js';

import { useParams } from 'react-router-dom';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"] {
      title,
      _id,
      slug,
      mainImage{
        asset->{
          _id,
          url
        }
      },
      publishedAt,
      body,
      "name": author->name,
      "authorImage": author->image
    }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost) return <div>Loading...</div>;

  return (
    <main className="bg-gray-200 min-h-screen p-5">
      <article className="container shadow-lg mx-auto bg-gray-100 rounded-lg">
        <header className="relative">
          <div className="absolute h-full w-full flex items-center justify-center p-8">
            <div className="bg-indigo-500 bg-opacity-75 rounded p-12 backdrop-filter backdrop-blur ">
              <h1 className="text-xl lg:text-6xl mb-4 starcraft">
                {singlePost.title}
              </h1>
              <br />
              <div className="flex justify-center text-gray-800">
                <img
                  src={urlFor(singlePost.authorImage).url()}
                  alt={singlePost.name}
                  className="w-7 h-7 rounded-full"
                />
                <p className="flex text-gray-900 items-center pl-2 text-xl">
                  <strong>{singlePost.name}</strong>
                </p>
              </div>
            </div>
          </div>
          <img
            src={singlePost.mainImage.asset.url}
            alt={singlePost.title}
            className="w-full object-cover rounded-t"
            style={{ height: '400px' }}
          />
        </header>
        <div className="px-8 lg:px-48 py-12 lg:py-20 prose lg:prose-xl max-w-full">
          <p>DATE: {singlePost.publishedAt}</p>
          <br />
          <br />
          <BlockContent
            blocks={singlePost.body}
            projectId="qr4omgu6"
            dataset="production"
          />
        </div>
      </article>
    </main>
  );
}
