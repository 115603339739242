import React, { useEffect, useState } from 'react';
import sanityClient from '../client.js';
import mountains from '../mountains.webp';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import { SocialIcon } from 'react-social-icons';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function About() {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "author"] {
      name,
      bio,
      "authorImage": image.asset->url
    }`
      )
      .then((data) => setAuthor(data[0]))
      .catch(console.error);
  }, []);

  if (!author) return <div>Loading...</div>;

  return (
    <main>
      <img
        src={mountains}
        alt="Mountains"
        className="fixed top object-cover w-full h-full"
      />
      <div className="p-3 lg:pt-48 container mx-auto relative">
        <section className="bg-indigo-800 rounded-lg shadow-2xl lg:flex p-20 backdrop-filter backdrop-blur-2xl bg-opacity-50">
          <img
            src={urlFor(author.authorImage).url()}
            alt={author.name}
            className=" invisible md:visible rounded w-0 h-0 lg:w-48 lg:h-48 md:p-5 md:mr-8 p-0 m-0"
          />
          <div className="text-lg flex flex-col justify-center">
            <h1 className="starcraft text-6xl text-indigo-400 mb-4">I am </h1>
            <br />
            <span className="text-indigo-100 ">{author.name}</span>
            <br />
            <div className="prose lg:prose-xl text-white">
              <BlockContent
                blocks={author.bio}
                projectId="qr4omgu6"
                dataset="production"
              />
            </div>
          </div>
          <div className="inline-flex my-6">
            <SocialIcon
              url="https://github.com/JohnESwan3"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
            <SocialIcon
              url="https://www.youtube.com/channel/UCEe9S764Dt4VC_T3lUUv1Cg"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
            <SocialIcon
              url="https://twitter.com/John3Swan"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
            <SocialIcon
              url="https://www.linkedin.com/in/jes3/"
              className="mr-4"
              target="_blank"
              fgColor="#fff"
              style={{ height: 35, width: 35 }}
            />
          </div>
        </section>
        <br />

        {/* Skills Section */}

        {/* Contact Section
        <section className="flex flex-auto justify-center items-center h-full bg-transparent item-center text-center ">
          <div className="md:w-1/2  bg-indigo-800 rounded shadow-2xl p-8 m-4">
            <h1 className="block text-center text-gray-200 text-2xl font-bold mb-6">
              Contact Me
            </h1>
            <form
              name="contact v1"
              method="POST"
              data-netlify="true"
              onSubmit="submit"
            >
              <input type="hidden" name="form-name" value="contact v1" />
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="first-name"
                  className="mb-2 font-bold text-xl text-gray-200"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  placeholder="First Name"
                  className="w-2/3 bg-gray-200 text-xl text-gray-900 mx-auto mb-2 rounded py-2 px-3"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="last-name"
                  className="mb-2 font-bold text-xl text-gray-200"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  placeholder="Last Name"
                  className="w-2/3 bg-gray-200 text-xl text-gray-900 mx-auto mb-2 rounded py-2 px-3"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="email"
                  className="mb-2 font-bold text-xl text-gray-200"
                >
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  className="w-2/3 bg-gray-200 text-xl text-gray-900 mx-auto mb-2 rounded py-2 px-3"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="message"
                  className="mb-2 font-bold text-xl text-gray-200"
                >
                  Message *
                </label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="5"
                  className="w-2/3 bg-gray-200 text-xl text-gray-900 mx-auto mb-2 rounded py-2 px-3"
                ></textarea>
              </div>
              <button
                className="block w-1/2 bg-indigo-600 hover:bg-indigo-900 text-white uppercase text-lg mx-auto p-4 rounded "
                type="submit"
              >
                Send
              </button>
            </form>
          </div>
        </section> */}
      </div>
    </main>
  );
}
