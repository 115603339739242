import React from 'react';
import image from '../bg.webp';

export default function Home() {
  return (
    <main>
      <img
        src={image}
        alt="Mountain"
        className="fixed object-cover w-full h-full top before:absolute before:inset-0 "
      />
      <section className="relative flex-column justify-center text-center min-h-screen pt-12 lg:pt-64 px-8">
        <h2 className=" text-indigo-800 text-2xl font-bold leading-none lg:leading-snug">
          Front End Developer
        </h2>
        <h1 className="text-xl text-indigo-600 font-bold leading-none lg:leading-snug home-name starcraft">
          John Swan
        </h1>
      </section>
    </main>
  );
}
