import React, { useEffect, useState } from 'react';
import sanityClient from '../client.js';
import { useParams } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';

export default function SingleProject() {
  const [singleProject, setSingleProject] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        _id,
        slug,
        mainImage{
            asset->{
                _id,
                url
            },
            alt
        },
        date,
        description,
        projectType,
        link,
        source,
        tags,
        body,
    }`
      )
      .then((data) => setSingleProject(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleProject) return <div>Loading...</div>;

  return (
    <main className="bg-gray-200 min-h-screen p-5">
      <article className="container shadow-lg mx-auto bg-gray-100 rounded-lg">
        <header className="relative flex justify-center text-center">
          <div className="absolute h-full w-full flex items-center justify-center p-8">
            <div className="bg-indigo-500 bg-opacity-75 rounded p-12 backdrop-filter backdrop-blur ">
              <h1 className="text-2xl lg:text-5xl mb-4">
                {singleProject.title}
              </h1>
            </div>
          </div>
          <img
            src={singleProject.mainImage.asset.url}
            alt={singleProject.title}
            className="w-full object-cover rounded-t"
            style={{ height: '400px' }}
          />
        </header>
        <div className="my-auto max-w-full prose flex ">
          <div className="container mx-auto py-6 flex space-x-5 justify-center uppercase flex-wrap bg-indigo-200">
            <p>{singleProject.tags[0]}</p>
            <p>{singleProject.tags[1]}</p>
            <p>{singleProject.tags[2]}</p>
            <p>{singleProject.tags[3]}</p>
            <p>{singleProject.tags[4]}</p>
          </div>
        </div>
        <div className="px-6 lg:px-48 py-10 lg:py-20 prose lg:prose-xl max-w-full">
          <p>DATE: {singleProject.date}</p>
          <p className="uppercase">Type: {singleProject.projectType}</p>
          <br />
          <BlockContent
            blocks={singleProject.body}
            projectId="qr4omgu6"
            dataset="productionf"
          />
          <br />
          <br />
          <a
            href={singleProject.source}
            rel="noopener noreferrer"
            target="_blank"
            className="text-indigo-500 font-bold hover:text-indigo-400 text-xl"
          >
            View on Github
          </a>
          <br /> <br />
          <a
            href={singleProject.link}
            rel="noopener noreferrer"
            target="_blank"
            className="text-indigo-100 font-bold hover:text-indigo-400 text-xl"
          >
            <button className="py-3 px-3 rounded w-full bg-gray-700 hover:bg-gray-9wwww00">
              Visit Site
            </button>
          </a>
        </div>
      </article>
    </main>
  );
}
