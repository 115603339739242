import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavBar() {
  return (
    <header className=" bg-gray-800">
      <div className="container mx-auto flex justify-center">
        <nav className="flex">
          <NavLink
            to="/"
            exact
            className="inflex-flex items-center py-5 mr-2 text-gray-400 hover:text-indigo-500 text-3xl font-bold starcraft tracking-widest"
            activeClassName="text-indigo-500"
          >
            JS
          </NavLink>
          <NavLink
            to="/post"
            className="inline-flex items-center py-3 px-2 my-6 rounded text-gray-500 hover:text-indigo-300"
            activeClassName="text-indigo-300 bg-gray-700"
          >
            Posts
          </NavLink>
          <NavLink
            to="/project"
            className="inline-flex items-center py-3 px-2 my-6 rounded text-gray-500 hover:text-indigo-300"
            activeClassName="text-indigo-300 bg-gray-700"
          >
            Projects
          </NavLink>
          <NavLink
            to="/about"
            className="inline-flex items-center py-3 px-2 my-6 rounded text-gray-500 hover:text-indigo-300"
            activeClassName="text-indigo-300 bg-gray-700"
          >
            About
          </NavLink>
        </nav>
      </div>
    </header>
  );
}
